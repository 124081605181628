import { STABLECOINS } from 'src/constants/tokens';
import {
  Currency,
  CurrencyAmount,
  Price,
  tryParseAmount
} from '@cryptoalgebra/kim-sdk';
import { useMemo } from 'react';
import { useNativePriceQuery } from '@app/hooks/v3/useNativePriceQuery';
import { useSingleTokenQuery } from '@app/hooks/v3/useSingleTokenQuery';

export function useUSDCPrice(currency: Currency | undefined) {
  const { data: bundle } = useNativePriceQuery();

  const { data: token } = useSingleTokenQuery({
    tokenId: currency ? currency.wrapped.address.toLowerCase() : ''
  });

  return useMemo(() => {
    if (!currency || !bundle || !token)
      return {
        price: undefined,
        formatted: 0
      };

    if (
      STABLECOINS.USDC.address.toLowerCase() ===
      currency.wrapped.address.toLowerCase()
    ) {
      return {
        price: new Price(STABLECOINS.USDC, STABLECOINS.USDC, '1', '1'),
        formatted: 1
      };
    }

    const tokenUSDValue =
      Number(token.derivedMatic) * Number(bundle.maticPriceUSD);

    const usdAmount = tryParseAmount(tokenUSDValue.toString(), currency);

    if (usdAmount) {
      return {
        price: new Price(
          currency,
          STABLECOINS.USDC,
          usdAmount.denominator,
          usdAmount.numerator
        ),
        formatted: Number(usdAmount.toSignificant())
      };
    }

    return {
      price: undefined,
      formatted: 0
    };
  }, [currency, bundle, token]);
}

export function useUSDCValue(
  currencyAmount: CurrencyAmount<Currency> | undefined | null
) {
  const { price, formatted } = useUSDCPrice(currencyAmount?.currency);

  return useMemo(() => {
    if (!price || !currencyAmount)
      return {
        price: null,
        formatted: null
      };

    try {
      return {
        price: price.quote(currencyAmount),
        formatted: Number(currencyAmount.toSignificant()) * formatted
      };
    } catch {
      return {
        price: null,
        formatted: null
      };
    }
  }, [currencyAmount, price, formatted]);
}

import React, { useCallback, useMemo } from 'react';
import { SwapButton } from '@app/components/Swap/components/SwapButton';
import { SwapField, SwapFieldType } from '@app/types/swap-field';
import {
  isWrap,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '@app/state/swapStore';
import {
  Currency,
  CurrencyAmount,
  maxAmountSpend,
  tryParseAmount
} from '@cryptoalgebra/kim-sdk';
import { computeFiatValuePriceImpact } from '@app/helpers/swap/computePriceImpact';
import { useUSDCValue } from '@app/hooks/v3/useUSDCValue';
import { TokenInputV3 } from '@app/components/Swap/components/TokenInput/TokenInputV3';
import { Address, NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { QuickAmountButtons } from '@app/components/Swap/components/QuickAmountButtons';
import { useBalance } from '@thirdweb-dev/react';
import { formatCurrency } from '@app/helpers/formatCurrency';
import { useConfig } from '@app/config';

export const SwapPair = () => {
  const {
    toggledTrade: trade,
    currencyBalances,
    parsedAmount,
    currencies
  } = useDerivedSwapInfo();

  const baseCurrency = currencies[SwapField.INPUT];
  const quoteCurrency = currencies[SwapField.OUTPUT];

  const { independentField, typedValue } = useSwapState();
  const dependentField: SwapFieldType =
    independentField === SwapField.INPUT ? SwapField.OUTPUT : SwapField.INPUT;

  const { onSwitchTokens, onCurrencySelection, onUserInput } =
    useSwapActionHandlers();

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(SwapField.INPUT, inputCurrency);
    },
    [onCurrencySelection]
  );

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(SwapField.OUTPUT, outputCurrency);
    },
    [onCurrencySelection]
  );

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(SwapField.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(SwapField.OUTPUT, value);
    },
    [onUserInput]
  );

  const config = useConfig();

  // const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue);

  // const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const showWrap: boolean = isWrap(currencies, config);

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [SwapField.INPUT]: parsedAmount,
            [SwapField.OUTPUT]: parsedAmount
          }
        : {
            [SwapField.INPUT]:
              independentField === SwapField.INPUT
                ? parsedAmount
                : trade?.inputAmount,
            [SwapField.OUTPUT]:
              independentField === SwapField.OUTPUT
                ? parsedAmount
                : trade?.outputAmount
          },
    [independentField, parsedAmount, showWrap, trade]
  );

  const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(
    currencyBalances[SwapField.INPUT]
  );
  const showMaxButton = Boolean(
    maxInputAmount?.greaterThan(0) &&
      !parsedAmounts[SwapField.INPUT]?.equalTo(maxInputAmount)
  );

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(SwapField.INPUT, maxInputAmount.toExact());
  }, [maxInputAmount, onUserInput]);

  const { price: fiatValueInputPrice, formatted: fiatValueInputFormatted } =
    useUSDCValue(
      tryParseAmount(
        parsedAmounts[SwapField.INPUT]?.toSignificant(
          (parsedAmounts[SwapField.INPUT]?.currency.decimals || 6) / 2
        ),
        baseCurrency
      )
    );

  const { price: fiatValueOutputPrice, formatted: fiatValueOutputFormatted } =
    useUSDCValue(
      tryParseAmount(
        parsedAmounts[SwapField.OUTPUT]?.toSignificant(
          (parsedAmounts[SwapField.OUTPUT]?.currency.decimals || 6) / 2
        ),
        quoteCurrency
      )
    );

  const priceImpact = computeFiatValuePriceImpact(
    fiatValueInputPrice,
    fiatValueOutputPrice
  );

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toFixed(
          (parsedAmounts[dependentField]?.currency.decimals || 6) / 2
        ) ?? ''
  };

  const { data: inputBalance, isLoading } = useBalance(
    baseCurrency?.isNative
      ? undefined
      : (baseCurrency?.wrapped.address as Address)
  );

  return (
    <>
      <TokenInputV3
        label="You pay"
        value={formattedAmounts[SwapField.INPUT] || ''}
        currency={baseCurrency}
        otherCurrency={quoteCurrency}
        handleTokenSelection={handleInputSelect}
        handleValueChange={handleTypeInput}
        handleMaxValue={handleMaxInput}
        fiatValue={fiatValueInputFormatted ?? undefined}
        showMaxButton={showMaxButton}
        priceImpact={priceImpact}
        field={SwapField.INPUT}
        filterByPools
      />
      <SwapButton onClick={onSwitchTokens} />
      <TokenInputV3
        label="You receive"
        value={formattedAmounts[SwapField.OUTPUT] || ''}
        currency={quoteCurrency}
        otherCurrency={baseCurrency}
        handleTokenSelection={handleOutputSelect}
        handleValueChange={handleTypeOutput}
        fiatValue={fiatValueOutputFormatted ?? undefined}
        priceImpact={priceImpact}
        field={SwapField.OUTPUT}
        filterByPools
      />
      <QuickAmountButtons
        useThreshold={baseCurrency?.isNative}
        amount={formattedAmounts[SwapField.INPUT]}
        balance={inputBalance ? Number(inputBalance.displayValue) : 0}
        onUpdate={handleTypeInput}
        onMax={handleMaxInput}
      />
    </>
  );
};

import { useDerivedSwapInfo } from '@app/state/swapStore';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import {
  computeRealizedLPFeePercent,
  warningSeverity
} from '@app/helpers/swap/prices';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InfoOutlineIcon
} from '@chakra-ui/icons';
import {
  Currency,
  Percent,
  Trade,
  TradeType,
  unwrappedToken
} from '@cryptoalgebra/kim-sdk';
import { TradeState } from '@app/types/trade-state';
import {
  Box,
  HStack,
  IconButton,
  Skeleton,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import s from '@app/components/Swap/components/TradeRouting/TradeRouting.module.scss';
import { TokenIcon } from '@app/components/TokenIcon';
import { Icon } from '@app/components/Icon';
import { decimalToFraction } from '@app/hooks/swap/useTrade';

export const SwapParams = () => {
  const {
    tradeState,
    toggledTrade: trade,
    allowedSlippage
  } = useDerivedSwapInfo();

  const [isExpanded, toggleExpanded] = useState(false);

  const { realizedLPFee, priceImpact } = useMemo(() => {
    if (!trade) return { realizedLPFee: undefined, priceImpact: undefined };

    const realizedLpFeePercent = computeRealizedLPFeePercent(trade);
    const realizedLPFee = trade.inputAmount.multiply(realizedLpFeePercent);
    const priceImpact = trade.priceImpact.subtract(realizedLpFeePercent);

    return { priceImpact, realizedLPFee };
  }, [trade]);

  const LPFeeString = realizedLPFee
    ? `${realizedLPFee.toSignificant(4)} ${realizedLPFee.currency.symbol}`
    : '-';

  function renderInfoRow(label: string, value: ReactNode, loading?: boolean) {
    return (
      <HStack justifyContent="space-between" w="100%">
        <Text fontWeight={400} fontSize="14px" color="whiteOpacity.70">
          {label}
        </Text>
        {loading ? (
          <Skeleton
            height="16px"
            width="64px"
            startColor="neutral.700"
            endColor="neutral.800"
            mb="2px"
          />
        ) : typeof value === 'string' ? (
          <Text fontWeight={400} fontSize="14px" color="white">
            {value}
          </Text>
        ) : (
          value
        )}
      </HStack>
    );
  }

  function renderInfo() {
    if (!trade) {
      return null;
    }

    if (tradeState.state === TradeState.LOADING) {
      return <Spinner />;
    }

    return (
      <Text color="neutral.300">
        {trade.tradeType === TradeType.EXACT_INPUT
          ? `${trade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${
              trade.outputAmount.currency.symbol
            }`
          : `${trade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${
              trade.inputAmount.currency.symbol
            }`}
      </Text>
    );
  }

  return (
    <VStack gap={3} w="100%" p={2}>
      <HStack justifyContent="space-between" w="100%">
        <HStack>
          <InfoOutlineIcon color="neutral.300" w="14px" h="14px" />
          {renderInfo()}
        </HStack>
        <IconButton
          fontSize={16}
          disabled={!trade}
          onClick={() => toggleExpanded(!isExpanded)}
          aria-label="Search database"
          icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        />
      </HStack>
      {isExpanded && trade && (
        <VStack gap={3} w="100%" mb={3}>
          {renderInfoRow(
            trade.tradeType === TradeType.EXACT_INPUT
              ? 'Minimum received'
              : 'Maximum sent',
            trade.tradeType === TradeType.EXACT_INPUT
              ? `${trade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${
                  trade.outputAmount.currency.symbol
                }`
              : `${trade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${
                  trade.inputAmount.currency.symbol
                }`
          )}
          {renderInfoRow('LP Fee', LPFeeString)}
          {renderInfoRow(
            'Price impact',
            <PriceImpact priceImpact={priceImpact} />
          )}
          {renderInfoRow(
            'Slippage tolerance',
            `${allowedSlippage.toFixed(2)}%`
          )}
          <HStack w="100%">
            <Text fontWeight={400} fontSize="14px" color="whiteOpacity.70">
              Trade routing
            </Text>
            <InfoOutlineIcon color="neutral.300" w="14px" h="14px" />
          </HStack>
          <SwapRoute trade={trade} />
        </VStack>
      )}
    </VStack>
  );
};

const SwapRoute = ({
  trade
}: {
  trade: Trade<Currency, Currency, TradeType>;
}) => {
  const path = trade.route.tokenPath;

  function renderDivider() {
    return (
      <Box
        className={s.divider}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          position: 'relative',
          borderBottom: '1px dashed',
          borderColor: 'gray.4'
        }}
      >
        <Icon
          name="arrowRightFilled"
          style={{
            position: 'absolute',
            top: '-8px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <HStack
        alignItems="center"
        gap={2}
        justifyContent="space-between"
        width="100%"
      >
        {path.map((token, idx) => {
          const val =
            unwrappedToken(token).symbol?.toLowerCase() === 'weth'
              ? 'ETH'
              : unwrappedToken(token).symbol;

          return (
            <Fragment key={`token-path-${idx}`}>
              {idx !== 0 && renderDivider()}
              <HStack alignItems="center" gap={2}>
                <TokenIcon symbol={val} width="32px" height="32px" />
                <div>{val}</div>
              </HStack>
            </Fragment>
          );
        })}
      </HStack>
    </>
  );
};

const PriceImpact = ({ priceImpact }: { priceImpact: Percent | undefined }) => {
  const severity = warningSeverity(priceImpact);

  const color =
    severity === 3 || severity === 4
      ? 'text-red-400'
      : severity === 2
      ? 'text-yellow-400'
      : 'text-white';

  return (
    <span className={color}>
      {priceImpact ? `${priceImpact.multiply(-1).toFixed(2)}%` : '-'}
    </span>
  );
};

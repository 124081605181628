import { useUserState } from 'src/state/userStore';
import {
  Currency,
  Percent,
  SwapRouter,
  Trade,
  TradeType
} from '@cryptoalgebra/kim-sdk';
import { useMemo } from 'react';
import { useAddress } from '@thirdweb-dev/react';

export function useSwapCallArguments(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: Percent
) {
  const account = useAddress();

  const { txDeadline } = useUserState();

  return useMemo(() => {
    if (!trade || !account) return [];

    const swapMethods: any[] = [];

    swapMethods.push(
      SwapRouter.swapCallParameters(trade, {
        feeOnTransfer: false,
        recipient: account,
        slippageTolerance: allowedSlippage,
        deadline: Date.now() + txDeadline * 1000
      })
    );

    if (trade.tradeType === TradeType.EXACT_INPUT) {
      swapMethods.push(
        SwapRouter.swapCallParameters(trade, {
          feeOnTransfer: true,
          recipient: account,
          slippageTolerance: allowedSlippage,
          deadline: Date.now() + txDeadline * 1000
        })
      );
    }

    return swapMethods.map(({ calldata, value }) => {
      return {
        calldata,
        value
      };
    });
  }, [trade, account, txDeadline, allowedSlippage]);
}

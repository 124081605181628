import { ArrowUpDownIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import React, { FC } from 'react';

interface Props {
  onClick: () => void;
}

export const SwapButton: FC<Props> = ({ onClick }) => {
  return (
    <Box position="relative" h="1px">
      <Button
        onClick={onClick}
        w="32px"
        h="32px"
        borderRadius="50%"
        position="absolute"
        top="-16px"
        left="50%"
        transform="translateX(-50%)"
        bg="neutral.700"
        color="neutral.100"
        _hover={{
          color: 'orange'
        }}
      >
        <ArrowUpDownIcon boxSize={4} />
      </Button>
    </Box>
  );
};
